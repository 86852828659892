import React from 'react';
import SectionHeader from './SectionHeader';
import { srcset } from './Helpers'
import './FocusBoxesHorizontal.scss';

class FocusBoxesHorizontal extends React.Component {
  $activeGroup = null
  $activeTab = null
  $groups = null
  $tabs = null
  activeIndex = 0
  classes = {
    'group-active': 'media-tabs__group--active',
    'tab-active': 'media-tabs__tab--active',
    'tab-completed': 'media-tabs__tab--completed',
  }
  lastIndex = null
  playerState = 'paused'
  tabsLength = null
  timer = null
  timerDuration = 7000

  constructor(props) {
    super(props)
    this.$container = React.createRef()
  }

  componentDidMount() {
    window.lazy.update()

    // start autoplay when section is in the viewport (after desktop image is lazyloaded)
    window.initMediaTabsAutplay = () => {
      this.init()
    }
    const section_id = this.props.data.section_id && this.props.data.section_id !== '' ? this.props.data.section_id.trim() : this.props.section_id_fallback || '';
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  componentWillUnmount() {
    this.autoplayStop()
  }

  init() {
    if (!window.matchMedia('(min-width: 1024px)').matches) return

    this.$groups = this.$container.current.querySelectorAll('[data-media-tabs-group]')
    this.$tabs = this.$container.current.querySelectorAll('[data-media-tabs-tab]')
    this.$activeGroup = this.$groups[0]
    this.$activeTab = this.$tabs[0]
    this.tabsLength = this.$tabs.length
    this.lastIndex = this.tabsLength - 1
    this.playerState = 'autoplay'
    this.$container.current.dataset.state = this.playerState

    this.autoPlayStart()
  }

  autoplayLoop() {
    // remove completed classes from tabs
    this.$tabs.forEach(el => {
      el.classList.remove(this.classes['tab-completed'])
    })

    // activate first tab to keep autoplay running
    this.tabActivate(0, false)
  }

  autoPlayStart() {
    this.$activeTab.classList.add(this.classes['tab-active'])

    // update active tab every 7 seconds
    this.timer = setInterval(() => {
      if (this.playerState === 'paused') return

      if (this.activeIndex + 1 > this.lastIndex) {
        this.autoplayLoop()
      } else {
        this.$activeTab.classList.add(this.classes['tab-completed'])
        this.tabActivate(this.activeIndex + 1, false)
      }
    }, this.timerDuration)
  }

  autoplayStop() {
    this.playerState = 'paused'
    this.$container.current.dataset.state = this.playerState
    window.clearInterval(this.timer)
  }

  tabActivate(index, stopAutoplay) {
    // remove active class from last active group
    if (this.$activeGroup) this.$activeGroup.classList.remove(this.classes['group-active'])

    // remove active class from last active tab
    if (this.$activeTab) this.$activeTab.classList.remove(this.classes['tab-active'])

    this.activeIndex = index

    // add active class to new active group
    this.$activeGroup = this.$groups[index]
    this.$activeGroup.classList.add(this.classes['group-active'])

    // add active class to new active tab
    this.$activeTab = this.$tabs[index]
    this.$activeTab.classList.add(this.classes['tab-active'])

    // stop autoplay onClick
    if (stopAutoplay) this.autoplayStop()
  }

  render() {
    const data = this.props.data
    const itemsLength = data.grid_layout.length
    const section_id = data.section_id && data.section_id !== '' ? data.section_id.trim() : this.props.section_id_fallback || ''

    return (
      itemsLength > 0 ? [
        <section id={section_id}
          class="media-tabs background-fill section-margin section-padding relative wrap"
          data-sticky-subnav-section="neighborhood"
          data-media-tabs
          data-state="autoplay"
          ref={this.$container}>
          <SectionHeader
            eyebrow={data.sub_text}
            headline_part_1={data.headline}
            headline_part_2={data.headline_part_2}
            headline_part_1_color={data.headline_part_1_color}
            headline_part_2_color={data.headline_part_2_color}
            headline_size={data.hasOwnProperty('headline_size') ? data.headline_size : 'large'}
          />

          <div class="media-tabs__gallery-container relative">
            {data.grid_layout.map((item, index) => (
              item.grid_headline && item.grid_description && item.grid_icon.url ? (
                <div class={`media-tabs__group flex full-width${index === 0 ? ' media-tabs__group--active' : ''}`}
                  data-media-tabs-group={`group-${index + 1}`}
                  style={{
                    '--flex-align-items': 'flex-end',
                    '--flex-wrap': 'wrap',
                    'height': index === 0 ? 'auto' : 'var(--media-tabs-gallery-item-height, auto)',
                    'left': '0',
                    'opacity': 'var(--media-tabs-gallery-item-opacity, 1)',
                    'pointer-events': 'var(--media-tabs-gallery-item-pointer-events, auto)',
                    'position': index === 0 ? 'static' : 'var(--media-tabs-gallery-item-position, static)',
                    'top': '0',
                    'margin-top': index === 0 ? '' : 'var(--media-tabs-gallery-item-row-gap, 68px)',
                    'transform': 'var(--media-tabs-gallery-item-transform, none)',
                    'transition': 'var(--media-tabs-gallery-item-transition, none)',
                  }}>
                  <figure class="media-tabs__media media-tabs__media--large aspect-ratio full-width"
                    style={{
                      '--aspect-ratio-height': '840',
                      '--aspect-ratio-height-mobile': '840',
                      '--aspect-ratio-width': '1920',
                      '--aspect-ratio-width-mobile': '1920',
                    }}>
                    <img class="media-tabs__media-item lazy object-fit"
                      data-srcset={srcset(item.grid_icon.url)}
                      data-lazy-function={index === 0 ? 'initMediaTabsAutplay' : ''}
                      sizes="auto"
                      alt={item.grid_icon.alt ? item.grid_icon.alt : item.grid_icon.title || ''} />
                  </figure>

                  <div class="media-tabs__tab full-width media-card hide-desktop" tabindex="-1">
                    {item.grid_headline &&
                      <div class="media-card__title font--medium"
                        style={{
                          'border-top': '1px solid var(--black)',
                          'color': 'var(--media-tabs-title-color, #525252)',
                          'font-size': 'var(--media-tabs-title-font-size, 22px)',
                          'margin-top': 'var(--media-tabs-title-margin-top, 16px)',
                          'padding-top': '16px',
                          'transition': 'color 300ms ease-in-out',
                        }}>
                        {item.grid_headline}
                      </div>
                    }
                    {item.grid_description &&
                      <div class="media-card__copy rte"
                        style={{
                          'color': 'var(--media-tabs-copy-color, #848484)',
                          'line-height': '1.375',
                          'margin-top': '16px',
                          'transition': 'color 300ms ease-in-out',
                        }}
                        dangerouslySetInnerHTML={{__html:item.grid_description.replace(/(?:\r\n|\r|\n)/g, '')}}>
                      </div>
                    }
                  </div>
                </div>
              ):('')
            ))}
          </div>

          <div class="media-tabs__copy flex hide-mobile relative">
            {data.grid_layout.map((item, index) => (
              item.grid_headline && item.grid_description && item.grid_icon.url ? (
                <button class={`media-tabs__tab media-card cursor text-align--left${index === 0 ? ' media-tabs__tab--active---' : ''}`}
                  style={{
                    'background': 'none',
                    'border': 'none',
                    'cursor': 'pointer',
                    'flex': '1',
                    'outline': 'none',
                    'padding-top': '32px',
                  }}
                  data-media-tabs-tab={`tab-${index + 1}`}
                  onClick={() => {
                    this.tabActivate(index, true)
                  }}>
                  <span class="media-card__border-top media-tabs__swiper-tabs-progress pointer-events-none"
                    style={{
                      'background-color': '#CBCBCB',
                      'display': 'block',
                      'height': '1px',
                      'position': 'relative',
                      'width': '100%',
                    }}>
                    <span class="media-tabs__swiper-tabs-progress"
                      style={{
                        'background-color': 'var(--black)',
                        'height': '100%',
                        'left': '0',
                        'position': 'absolute',
                        'top': '0',
                        'transform-origin': 'left center',
                        'transform': 'scaleX(var(--media-tabs-progress-scale-x, 0))',
                        'transition': 'transform var(--media-tabs-progress-transition-duration, var(--media-tabs-active-duration, 500ms)) linear',
                        'width': '100%',
                      }}
                      ></span>
                  </span>

                  {item.grid_headline &&
                    <div class="media-card__title font--medium pointer-events-none"
                      style={{
                        'color': 'var(--media-tabs-title-color, #BFBFBF)',
                        'font-size': '24px',
                        'margin-top': '24px',
                        'padding-right': '30px',
                        'transition': 'color 300ms ease-in-out',
                      }}>
                      {item.grid_headline}
                    </div>
                  }

                  {item.grid_description &&
                    <div class="media-card__copy font-size max-width rte pointer-events-none"
                      style={{
                        '--font-size-desktop': '20px',
                        'color': 'var(--media-tabs-copy-color, #BFBFBF)',
                        'max-width': '43ch',
                        'line-height': '1.375',
                        'margin-top': '16px',
                        'padding-right': '30px',
                        'transition': 'color 300ms ease-in-out',
                      }}
                      dangerouslySetInnerHTML={{__html:item.grid_description.replace(/(?:\r\n|\r|\n)/g, '')}}>
                    </div>
                  }
                </button>
              ):('')
            ))}
          </div>
        </section>
      ]:['']
    )
  }
}

export default FocusBoxesHorizontal