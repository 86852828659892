import React from 'react';
import HeadingSection from './HeadingSection';
import { srcset } from './Helpers'
import './FocusBoxesVertical.scss';
import {Link} from "react-router-dom";

class FocusBoxesVertical extends React.Component {

    componentDidMount() {
        window.JQUERY(".accordion-newHomeSection .item:first-child  .itemHead , .accordion-newHomeSection .item:first-child  .imgarea").addClass("active");
        window.JQUERY(".accordion-newHomeSection .item:first-child .itemHead .mobileAccordion").slideDown();
        window.JQUERY(".accordion-newHomeSection .item  .itemHead").click(function() {
            if (!window.JQUERY(this).hasClass("active")){
                window.JQUERY(".accordion-newHomeSection .item  .itemHead").removeClass("active");
                window.JQUERY(this).addClass("active");
                window.JQUERY(".accordion-newHomeSection .item  .imgarea").removeClass("active");
                window.JQUERY(this).siblings(".imgarea").addClass("active");
                window.JQUERY(".accordion-newHomeSection .item .itemHead .mobileAccordion").slideUp();
                window.JQUERY(this).find('.mobileAccordion').slideDown();
            }
        })
        const focus_data = this.props.focus_data;
        const section_id = focus_data.section_id && focus_data.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
                }
            }, 2000)
        }

      window.lazy.update()
    }
    render() {
        var focus_data = this.props.focus_data;
        var section_id = focus_data.section_id && focus_data.section_id.trim();
        var section_id = focus_data.section_id && focus_data.section_id !== '' ? focus_data.section_id.trim() : this.props.section_id_fallback || ''
       return (
        focus_data.grid_layout.length > 0 ? [
               <section className="main-div section-margin section-padding wrap" id={section_id}>
                    <div className="accordion-newHomeSection">
                        <div className={`accordionWrapper${ focus_data.wide_content ? ' widerContent' : '' }`}>
                           <div className="mobileImageArea"></div>
                            <div className="headerArea">
                              <HeadingSection
                                subtitle={focus_data.sub_text}
                                title_part_1={focus_data.headline}
                                title_part_2={focus_data.headline_part_2}
                                headline_part_1_color={focus_data.headline_part_1_color}
                                headline_part_2_color={focus_data.headline_part_2_color}
                                headline_size={focus_data.hasOwnProperty('headline_size') ? focus_data.headline_size : 'large'}
                              />
                                {focus_data.description  ? (
                                    <div className="description" dangerouslySetInnerHTML={{__html:focus_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                ):('')}
                            </div>
                            <div className="accordionWrapperItem">
                               {focus_data.grid_layout.map((item,key) => (
                                    item.grid_headline && item.grid_description && item.grid_icon.url ? (
                                    <div className="item">
                                        <div className="itemHead">
                                            {item.grid_headline && (
                                                <h3 tabIndex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                }}
                                                className="focus-boxes-heading">{item.grid_headline}</h3>
                                            )}
                                            <div className="mobileAccordion">
                                                <div className="mobileImgArea">
                                                    {item.grid_icon.url ? (
                                                        <img className="lazy" data-srcset={srcset(item.grid_icon)} title={item.grid_icon.title} alt={item.grid_icon.alt ? item.grid_icon.alt :item.grid_icon.title } />
                                                    ):('')}
                                                </div>
                                                {(item.grid_description || (item.grid_cta_label && item.grid_cta_link)) && (
                                                    <div className="description mobileDesc">
                                                        {item.grid_description && (
                                                            <p dangerouslySetInnerHTML={{__html:item.grid_description.replace(/(?:\r\n|\r|\n)/g, '')}} ></p>
                                                        )}
                                                        {item.grid_cta_label && item.grid_cta_link && (
                                                            <Link to={item.grid_cta_link}>{ item.grid_cta_label }</Link>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {(item.grid_description || (item.grid_cta_label && item.grid_cta_link)) && (
                                                <div className="description focus-boxes-description"
                                                     tabIndex="0"
                                                     onKeyPress={(e)=>{
                                                         if (e.keyCode === 13 || e.which === 13) e.target.click();
                                                     }}
                                                >
                                                    {item.grid_description && (
                                                        <p dangerouslySetInnerHTML={{__html:item.grid_description.replace(/(?:\r\n|\r|\n)/g, '')}} ></p>
                                                    )}
                                                    {item.grid_cta_label && item.grid_cta_link && (
                                                        <Link to={item.grid_cta_link}>{ item.grid_cta_label }</Link>
                                                    )}
                                                </div>
                                            )}
                                            <div tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                }}
                                                className="arrowRight">
                                            </div>
                                        </div>
                                        <div className="imgarea">
                                            {item.grid_icon.url ? (
                                                <img class="lazy" data-srcset={srcset(item.grid_icon)} title={item.grid_icon.title} alt={item.grid_icon.alt ? item.grid_icon.alt : item.grid_icon.title } />
                                            ):('')}
                                        </div>
                                    </div>
                                    ):('')
                                ))}
                            </div>
                        </div>
                    </div>
            </section>
        ]:['']
        );
  }
}
export default FocusBoxesVertical;
