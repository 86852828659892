import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { searchClient } from '../Search/helpers/initAlgolia';
import React, { Fragment } from 'react';
import config from '../Search/config/config';
import AutocompleteHit from './AutocompleteHit';

const createNeighborhoodsPlugin = ( ...rest ) => {
	return {
		...rest,
		getSources( props ) {
			const { query, state } = props;
			return [
				{
					sourceId: 'neighborhoods',
					templates: {
						header( { items } ) {
							if ( items.length === 0 ) {
								return <Fragment />;
							}
							return (
								<Fragment>
									<span className="aa-SourceHeaderTitle">
                                        Neighborhoods
									</span>
									<span className="aa-SourceHeaderLine" />
								</Fragment>
							);
						},
						item( params ) {
							console.log( 'params', params );
							const { item, html } = params;
							return <AutocompleteHit hit={item} type="neighborhood" insights={state.context.algoliaInsightsPlugin.insights} />;
						},
					},
					onSelect( { item } ) {
						return item.url;
					},
					getItemInputValue( { item } ) {
						return item.name;
					},
					getItems() {
						if ( ! query || state.context.selectedState ) {
							return [];
						}
						return getAlgoliaResults( {
							searchClient,
							queries: [
								{
									indexName: `${ config.ALGOLA_ENV }_${ config.ALGOLIA_INDEX_DEFAULT }`,
									query,
									params: {
										clickAnalytics: true,
										analytics: true,
									},
								},
							],
						} );
					},
				},
			];
		},
	};
};

export default createNeighborhoodsPlugin;
