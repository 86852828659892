import React from 'react'
import './LargeCopy.scss'

class LargeCopy extends React.Component {
  render() {
    return (
      this.props.data.copy ? (
        <section className="large-copy font--book section-margin--off rte section-padding wrap"
          style={{
            '--large-copy-background-color': '#97B701',
            '--section-padding-bottom':'50px',
            '--section-padding-top':'100px',
            '--section-padding-bottom-desktop':'clamp(70px, 5vw, 100px)',
            '--section-padding-top-desktop':'clamp(140px, 10vw, 374px)',
            'background-color': this.props.data.background_color || '#BED437',
            'color': this.props.data.text_color || '#fff',
            'font-size':'var(--large-copy-font-size, 32px)',
            'letter-spacing':'-0.05em',
            'line-height':'var(--large-copy-line-height, 1.2)',
          }}>
          <div className="large-copy__content font--book rte"
            style={{
              'max-width': 'var(--large-copy-max-width, 40ch)',
            }}
            dangerouslySetInnerHTML={{ __html: this.props.data.copy.replace(/(?:\r\n|\r|\n)/g, '') }}>
          </div>
        </section>
      ):('')
    )
  }
}

export default LargeCopy