import React from 'react'
import SectionHeader from './SectionHeader'

class ImageCopyGrid extends React.Component {
  componentDidMount() {
    window.lazy.update()
  }

  render() {
    return (
      this.props.data.description || this.props.data.main_image ? (
        <section className="media-staggered section-margin section-padding padding-vertical--off wrap"
          data-sticky-subnav-section="online-design-studio"
          id="online-design-studio">
          <SectionHeader
            eyebrow={this.props.data.sub_text}
            headline_part_1={this.props.data.headline}
            headline_part_2={this.props.data.headline_part_2}
            headline_part_1_color={this.props.data.headline_part_1_color}
            headline_part_2_color={this.props.data.headline_part_2_color}
            headline_size={this.props.data.hasOwnProperty('headline_size') ? this.props.data.headline_size : 'large'}
          />

          <div className="media-staggered__grid grid"
            style={{
              '--grid-display-mobile': 'block',
              '--grid-column-gap': '50px',
              '--grid-columns': '2',
            }}>
            <div className="media-staggered__copy-item" style={{}}>
              {this.props.data.main_image &&
                <figure className="media-staggered__media aspect-ratio"
                  style={{
                    '--aspect-ratio-height': '615',
                    '--aspect-ratio-width': '888',
                    '--aspect-ratio-height-mobile': '200',
                    '--aspect-ratio-width-mobile': '342',
                  }}>
                  <img className="media-staggered__media-item lazy object-fit"
                    alt={this.props.data.main_image.alt}
                    data-srcset={this.props.data.main_image.url}
                    sizes="auto"
                  />
                </figure>
              }
              <div className="media-gallery__copy media-card"
                style={{
                  '-webkit-flex': '1',
                  '-ms-flex': '1',
                  'flex': '1',
                }}>
                {this.props.data.title &&
                  <div className="media-card__title font-size margin-vertical padding-vertical font--medium"
                    style={{
                      '--font-size-desktop': '24px',
                      '--font-size-mobile': '22px',
                      '--margin-vertical-bottom-desktop': '24px',
                      '--margin-vertical-bottom-mobile': '24px',
                      '--margin-vertical-top-desktop': '56px',
                      '--margin-vertical-top-mobile': '30px',
                      '--padding-vertical-bottom-desktop': '21px',
                      '--padding-vertical-bottom-mobile': '16px',
                      '--padding-vertical-top-desktop': '0',
                      '--padding-vertical-top-mobile': '0',
                      'border-bottom': '1px solid var(--black)',
                      '-webkit-transition': 'color 300ms ease-in-out',
                      'transition': 'color 300ms ease-in-out',
                    }}>
                    {this.props.data.title}
                  </div>
                }
                {this.props.data.description &&
                <div className="media-card__copy font-size rte"
                  style={{
                      'color': 'var(--media-tabs-copy-color, #848484)',
                      'line-height': '1.375',
                      '--font-size-line-height-desktop': '1.3',
                      '--font-size-line-height-mobile': '1.375',
                      '--font-size-desktop': '20px',
                      '--font-size-mobile': '16px',
                      'max-width': 'var(--media-card-copy-max-width, 70ch)',
                      '-webkit-transition': 'color 300ms ease-in-out',
                      'transition': 'color 300ms ease-in-out',
                    }}
                    dangerouslySetInnerHTML={{ __html: this.props.data.description.replace(/(?:\r\n|\r|\n)/g, '') }}>
                  </div>
                }
                {this.props.data.cta_link && this.props.data.cta_label &&
                  <a className="button margin-vertical"
                    style={{
                      '--margin-vertical-bottom-desktop': '0',
                      '--margin-vertical-bottom-mobile': '0',
                      '--margin-vertical-top-desktop': '65px',
                      '--margin-vertical-top-mobile': '40px',
                    }}
                    target={this.props.data.open_in_new_tab ? '_blank' : '_self'}
                    href={this.props.data.cta_link}>
                    <span>{this.props.data.cta_label}</span>
                  </a>
                }
              </div>
            </div>
            {this.props.data.secondary_image &&
              <div className="media-staggered__media-item margin-vertical"
                style={{
                  '--margin-vertical-top-mobile': '70px',
                  '--margin-vertical-top-tablet': '0',
                }}>
                <figure className="media-staggered__media aspect-ratio"
                  style={{
                    '--aspect-ratio-height': '990',
                    '--aspect-ratio-width': '841',
                    '--aspect-ratio-height-mobile': '200',
                    '--aspect-ratio-width-mobile': '342',
                  }}>
                  <img className="media-staggered__media-item lazy object-fit"
                    alt={this.props.data.secondary_image.alt}
                    data-srcset={this.props.data.secondary_image.url}
                    sizes="auto"
                  />
                </figure>
              </div>
            }
          </div>
        </section>
      ):('')
    )
  }
}

export default ImageCopyGrid