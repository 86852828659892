import React from 'react'
import './HeroSubNav.scss'
import $ from 'jquery'
import QmiReserveForm from './QmiReserveForm.jsx'
import StageQmiReserveForm from './StageQmiReserveForm.jsx'
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import { Modal } from 'react-bootstrap'


class HeroSubNav extends React.Component {
  vidyarVideoLoaded = false
  youtubeLoaded = false
  youtubePlayerState = null
  $youtubeVideo = null
  $videoModal = null

  constructor() {
    super()

    this.state = {
      reserveform: true,
      showHide : false
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleModalShowHide = this.handleModalShowHide.bind(this)

  }

  handleModalShowHide(){
    this.setState({showHide : !this.state.showHide})
  }

  handleClose() {
    this.setState({show: null});
  }

  handleShow(id) {
    this.setState({show: id});
  }

  loadVideo = () => {
    this.$youtubeVideo = document.getElementById('youtubeVideo')

    if (this.$youtubeVideo) {
      this.player = new window.YT.Player('youtubeVideo', {
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      })
    }

    this.youtubeLoaded = true
  }

  onPlayerReady = event => {
    event.target.playVideo()
  }

  onPlayerStateChange = event => {
    if (event.data === window.YT.PlayerState.ENDED) {
      event.target.seekTo(0)
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      this.youtubePlayerState = 'paused'
    } else if (event.data === window.YT.PlayerState.PLAYING) {
      this.youtubePlayerState = 'playing'
    }
  }

	componentDidMount() {
    this.stickySubnav()

    $(document).on('click', e => {
      if (!document.documentElement.classList.contains('details-modal--active')) return

      const $el = $(e.target)

      if ($el.closest('.details__modal').length === 0 && $el.closest('[data-chat-modal-open-button]').length === 0) {
        this.closeChatModal()
      }
    })
  }

  onClickInterestList(e) {
    e.preventDefault()

    const $interestBtn = document.getElementById("interest_click_btn")

    if ($interestBtn) $interestBtn.click()
  }

  sitemap_data_layer_push(param) {
    this.setState({ value:param })

    if (param && param==='Interactive_Sitemap') {
      window.dataLayer.push({'event':'interactiveSitemap'})
    }
  }

  smoothScroll(e,link) {
    const $el = $(e.target)
    if (link.slug === 'community-map') {
      this.setState({showHide : !this.setState.showHide})
      console.log('modalopened');
    }else{
      const $section = $el[0].hasAttribute('data-smooth-scroll') ? $($el.data('smooth-scroll')) : $($el.attr('href'))

      if (!$section[0]) return

      e.preventDefault()

      $('html, body').animate({
        'scrollTop': $section.offset().top - $('.hero-subnav__container--sticky').height() + 1
      }, 1000)
    }
  }

  closeChatModal() {
    document.documentElement.classList.remove('details-modal--active')

    if (this.$videoModal) this.$videoModal.pause()

    if (this.player) this.player.pauseVideo()

    this.$videoModal = null
  }

  openChatModal(e) {
    window.dataLayer.push({'event':'getInTouchClick'});
    document.documentElement.classList.toggle('details-modal--active')
    this.$videoModal = document.querySelector('[data-details-modal-video]')

    if (this.$videoModal) this.$videoModal.play()

    if (this.player) {
      this.player.playVideo()
    } else {
      setTimeout(() => {
        if (!this.youtubeLoaded) this.loadVideo()
      }, 300)
    }
  }

  stickySubnav () {
    // Show stckynav when scrolled down a bit
    const $el = document.querySelector('[data-sticky-subnav-threshold]')

    if (!$el) return

    const observer = new IntersectionObserver(([entry]) => {
      document.documentElement.classList[entry.boundingClientRect.y < 0 ? 'add' : 'remove']('subnav--sticky')
    })

    observer.observe($el)

    // -- Add active class to sticky  -- //
    const stickySubnavThresholdOffset = (-1 * (parseFloat($('.hero-subnav__container--sticky').height() || 0) + 1)) + 'px'

    const $stickySubnavSections = document.querySelectorAll('[data-sticky-subnav-section]')

    const stickySubnavSectionObserver = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry, entryIndex) {
        const id = entry.target.dataset.stickySubnavSection
        const $navEl = $(`[data-sticky-subnav-link="${id}"]`)

        if (entry.isIntersecting) {
          if (!document.documentElement.classList.contains('subnav--sticky')) {
            $(`[data-sticky-subnav-link]:first-of-type`).addClass('hero-subnav__link--active')
            return
          }

          // all sections in viewport's nav link will get active class, sometimes > 1 active at same time - using css to only display first one with active state
          if (!window.stickySubnavLinkClicked) {
            $navEl.addClass('hero-subnav__link--active')
          }
        } else {
          if (!document.documentElement.classList.contains('subnav--sticky')) return
          if (!window.stickySubnavLinkClicked) $navEl.removeClass('hero-subnav__link--active')
        }
      })
    },{
      rootMargin: `${stickySubnavThresholdOffset} 0px 0px 0px`,
    })

    // watch all sections (with exceptions)
    Array.from($stickySubnavSections).forEach((el) => stickySubnavSectionObserver.observe(el))
  }

  stickyReserveClick(e) {
    e.preventDefault()
    $('a[href="#tour_forms"]').trigger('click')
  }
  //clicktocall data-layer
  calltoaction_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='clickToCall') {
        window.dataLayer.push({'event':'clickToCall'});
    }
  }
  render() {
    const data = this.props.data
    const pageData = this.props.page_data
    const posttype = this.props.posttype
    const agent_info = data.agent_info
    const isSticky = this.props.hasOwnProperty('sticky') && this.props.sticky
    const classes = `hero-subnav__container${isSticky ? ' hero-subnav__container--sticky' : ' relative'}${this.props.hasOwnProperty('hide_details') && this.props.hide_details ? ' hero-subnav__container--hide-details' : ''}`
    var current_url = window.location.host;
    if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
        var stageForm = true;
    }else{
      var stageForm = false;
    }
    if (isSticky) this.state.reserveform = false

    // console.log('HeroSubNav.jsx data', data)
    // console.log('HeroSubNav.jsx interactive_media', this.props.interactive_media)
    // console.log('HeroSubNav.jsx pageData', pageData)

    // if (!isSticky && pageData.hasOwnProperty('chat_widget') && pageData.chat_widget.hasOwnProperty('script') && pageData.chat_widget.hasOwnProperty('button')) {
    //   $('head').append(pageData.chat_widget.script)
    // }

    const stylesDefault = {
      'margin-top': '-24px',
      'overflow': 'hidden',
      'padding-top': '24px',
      'pointer-events': 'auto',
      'z-index': '1',
      'width': '100vw',
    }

    const stylesSticky = {
      'pointer-events': 'auto',
      'z-index': '10',
    }

    const styles = isSticky ? stylesSticky : stylesDefault

    let subnavLinks = []

    // -- Videos -- //
    let video = null
    let videoType = null

    if (agent_info.video) {
      const queryStringSeparator = agent_info.video.indexOf('?') !== 1 ? '?' : '&'

      if (agent_info.video.indexOf('.mp4') !== -1 || agent_info.video.indexOf('.webm') !== -1) {
        videoType = 'inline'
        video = <video class="details__modal-video lazy object-fit" data-details-modal-video autoplay loop muted playsinline data-src={agent_info.video}></video>
      } else {
        const loadingIcon = <svg data-mouse-follow="loading" class="mouse-follow height loaded width position hide-mobile z-index" style={{'--height': '60px', '--position-left': '50%','--position-top': '50%', '--width': '60px', '--z-index': '0', 'background-color': '#fff','border-radius': '100%', 'pointer-events': 'none','position': 'absolute', 'transform': 'translate(-50%, -50%)', }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="84px" height="84px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="#242424" stroke-width="3" r="16" stroke-dasharray="65.97344572538566 23.991148575128552"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg>

        if (agent_info.video.indexOf('youtu') !== -1) {
          videoType = 'youtube'
          const youtubeId = agent_info.video.indexOf('/embed/') !== -1 ? agent_info.video.split('/embed/')[1] : agent_info.video.indexOf('v=') !== -1 ? agent_info.video.split('v=')[1] : agent_info.video.indexOf('.be/') !== -1 ? agent_info.video.split('be/')[1] : null

          if (youtubeId) {
            video = <>{loadingIcon}<iframe id="youtubeVideo" class="hero-subnav__video lazy cover" width="560" height="315" data-src={`https://www.youtube.com/embed/${youtubeId}${queryStringSeparator}?autoplay=1&showinfo=0&controls=1&loop=1&mute=0&enablejsapi=1&html5=1&origin=${ window.location.origin }&playsinline=1&rel=0&showinfo=0`} title="Video player" frameBorder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></>
          }
        } else if (agent_info.video.indexOf('vidyard') !== -1) {
          videoType = 'vidyard'
          video = <>{loadingIcon}<iframe class="hero-subnav__video lazy cover" width="560" height="315" data-src={agent_info.video} title="Video player" frameBorder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></>
        }
      }
    }

    const closeButton = <button class="deatils__modal-close flex"
      onClick={() => {
        this.closeChatModal()
      }}
      style={{
        '--flex-align-items': 'center',
        '--flex-justify-content': 'center',
        'background': 'rgba(0, 0, 0, 0.8)',
        'border': 'none',
        'border-radius': '50%',
        'bottom': videoType === 'youtube' ? '7px' : 'auto',
        'color': '#fff',
        'cursor': 'pointer',
        'height': '32px',
        'position': 'absolute',
        'right': videoType === 'youtube' ? '23px' : '20px',
        'top': videoType === 'youtube' ? 'auto' : '20px',
        'width': '32px',
      }}
      type="button">
      <svg xmlns="http://www.w3.org/2000/svg" width="8.931" height="8.93" viewBox="0 0 8.931 8.93"
        style={{
          'height': '11px',
          'left': '50%',
          'position': 'absolute',
          'top': '50%',
          'transform': 'translate(-50%, -50%)',
          'width': '11px',
        }}>
        <g transform="translate(11.878 4.475) rotate(135)">
          <path d="M0,0,3.655,3.655,0,7.31" transform="translate(5.169 0) rotate(45)" fill="none" stroke="#fff" stroke-width="2"/>
          <path d="M0,0,3.655,3.655,0,7.31" transform="translate(5.301 10.497) rotate(-135)" fill="none" stroke="#fff" stroke-width="2"/>
          <path d="M0,7.31,3.655,3.655,0,0" transform="translate(10.47 5.169) rotate(135)" fill="none" stroke="#fff" stroke-width="2"/>
          <path d="M0,7.31,3.655,3.655,0,0" transform="translate(0 5.328) rotate(-45)" fill="none" stroke="#fff" stroke-width="2"/>
        </g>
      </svg>
      <span class="visually-hidden">Close modal</span>
    </button>

    // add neighborhhod link to community page
    if (posttype === 'mpc' && pageData.hasOwnProperty('neighborhood') && pageData.neighborhood) subnavLinks.push({
      'title': data.hero_subnav.neighborhood_label_community_page || 'Neighborhoods',
      'slug': 'neighborhood',
    })

    // Add home plans link if residences arrays not empty
    if (posttype === 'neighborhood' && pageData.hasOwnProperty('residence') && pageData.residence.length > 0) subnavLinks.push({
      'title': data.hero_subnav.floor_plans_label || 'Floor Plans',
      'slug': 'home-plans',
    })

    // Add home plans link if residences arrays not empty
    if (posttype === 'neighborhood' && pageData.hasOwnProperty('qmi') && pageData.qmi.length > 0) subnavLinks.push({
      'title': data.hero_subnav.available_homes_label || 'Available Homes',
      'slug': 'available-homes',
    })

    // add neighborhhod link to neighborhood page
    const focus_boxes_with_icons = data.hasOwnProperty('component') ? data.component.filter(x => x.acf_fc_layout === 'focus_boxes_with_icons') : []
    const focus_boxes_neighborhood = focus_boxes_with_icons.length > 0 && (focus_boxes_with_icons[0].headline.indexOf('eighborhood') !== -1 || focus_boxes_with_icons[0].headline_part_2.indexOf('eighborhood') !== -1 || focus_boxes_with_icons[0].sub_text.indexOf('eighborhood') !== -1 || focus_boxes_with_icons[0].section_id.indexOf('eighborhood') !== -1)

    if (posttype === 'neighborhood' && data.hasOwnProperty('component') && focus_boxes_with_icons.length > 0 && focus_boxes_neighborhood) subnavLinks.push({
      'title': data.hero_subnav.neighborhood_label || 'Neighborhood',
      'slug': 'neighborhood',
    })

    if (posttype === 'mpc' && pageData.hasOwnProperty('qmi') && pageData.qmi.length > 0) subnavLinks.push({
      'title': data.hero_subnav.available_homes_label || 'Available Homes',
      'slug': 'available-homes',
    })

    const inlineComponents = data.hasOwnProperty('component') && data.component.filter(x => x.acf_fc_layout === 'inline_component')

    // Add Floorplan and vitual tour link to quick move in page
    if (posttype === 'qmi') {
      if (!inlineComponents.length > 0) return

      inlineComponents.forEach(c => {
        const isFloorPlan = c.cta_link && c.cta_link.indexOf('thebdxinteractive.com/floorplan') !== -1
        const isVirtualTour = c.cta_link && c.cta_link.indexOf('thebdxinteractive.com/cloud') !== -1

        if (isFloorPlan) {
          subnavLinks.push({
            'title': data.hero_subnav.floorplan_label || 'Floorplan',
            'slug': 'floorplan',
          })
        }

        if (isVirtualTour) {
          subnavLinks.push({
            'title': data.hero_subnav.virtual_tour_label || 'Virtual Tour',
            'slug': 'virtual-tour',
          })
        }
      })

      // const floorplan = inlineComponents.filter(x => x.cta_link && x.cta_link.indexOf('thebdxinteractive.com/floorplan') !== -1)

      // if (floorplan.length > 0 ) subnavLinks.push({
      //   'title': data.hero_subnav.floorplan_label || 'Floorplan',
      //   'slug': 'floorplan',
      // })
    }

    // add gallery link
    const media_gallery = data.hasOwnProperty('component') ? data.component.filter(x => x.acf_fc_layout === 'media_gallery') : []
    if (data.hasOwnProperty('component') && media_gallery.length > 0 && media_gallery[0].gallery_images.length > 0) subnavLinks.push({
      'title': data.hero_subnav.gallery_label || 'Gallery',
      'slug': 'gallery',
    })

    // Add Floorplan and vitual tour link to quick move in page
    if (posttype === 'qmi') {
      if (!inlineComponents.length > 0) return

      inlineComponents.forEach(c => {
        const isCommunityMap = c.cta_link && c.cta_link.indexOf('salesarchitect.exsquared.com/ISP-3?bdxCommunityID') !== -1 || c.iframe_heading.toLowerCase().indexOf('community map') !== -1

        if (isCommunityMap) {
          subnavLinks.push({
            'title': data.hero_subnav.community_map_label || 'Community Map',
            'slug': 'community-map',
          })
        }
      })
    } else {
      // Add Community Map link if neighborhood_details.component.filter(x => x.acf_fc_layout === 'inline_component').length > 0 !== 0
      if (data.hasOwnProperty('component') && data.component.filter(x => x.acf_fc_layout === 'inline_component').length > 0 && data.component.filter(x => x.section_id === 'community-map' && x.cta_link !== '').length > 0)  subnavLinks.push({
        'title': data.hero_subnav.community_map_label || 'Community Map',
        'slug': 'community-map',
      })
    }

    // Add Virtual Tools link if neighborhood_details.virtual_tool !== 0
    if (data.hasOwnProperty('virtual_tool') && data.virtual_tool !== 0) subnavLinks.push({
      'title': 'Virtual Tools',
      'slug': 'virtual-tools',
    })

    if (this.props.hasOwnProperty('links')) subnavLinks = this.props.links

    return (
      <>
        {/* {!isSticky && pageData.hasOwnProperty('chat_widget') && pageData.chat_widget.hasOwnProperty('script') && pageData.chat_widget.hasOwnProperty('button') &&
          <div dangerouslySetInnerHTML={{ __html: pageData.chat_widget.button }} />
        } */}
        <div class={classes}
          data-sticky-subnav={isSticky}
          style={styles}>
          <nav class="hero-subnav__nav flex"
            style={{
              '--flex-direction': 'var(--hero-subnav-flex-direction, column)',
              '--flex-justify-content': 'var(--hero-subnav-justify-content, flex-start)',
              'background-color': '#97B700',
            }}>
            <div class="hero-subnav__buttons flex flex-shrink relative"
              style={{
                '--flex-direction': 'row',
                '--flex-justify-content': 'flex-start',
                'height': 'var(--hero-subnav-buttons-height, 50px)',
                'margin-bottom': 'var(--hero-subnav-buttons-margin-bottom, 0)',
                'position': 'relative',
                'top': 'var(--hero-subnav-buttons-top, 0)',
                'width': 'var(--hero-subnav-buttons-width, 100%)',
                'z-index': '10',
              }}>
              {this.props.hasOwnProperty('is_qmi') && this.props.is_qmi ? (
                <>
                  {!isSticky &&
                   <>
                   <a href="javascript:void(0)"
                      class="hero-subnav__button cursor flex full-height hero-subnav__button--text flex-grow flex-shrink text-align--left wrap"
                      id="tour_click_btns"
                      onClick={()=>{
                        window.dataLayer.push({'event':'homeReservationFormClick'})
                        this.handleShow('reserve_form')
                      }}
                      style={{
                        '--flex-align-items': 'flex-start',
                        '--flex-direction': 'column',
                        '--flex-justify-content': 'center',
                        'background-color': '#D2ED2B',
                        'border': 'none',
                        'color': 'var(--black)',
                        'flex': 'var(--hero-subnav-button-small-flex, inherit)',
                        'line-height': '1.2',
                        'margin': '0',
                        'min-width': 'var(--hero-subnav-button-small-min-width, max(127px, 38.97435%))',
                        'text-decoration': 'none',
                        'width': 'auto',
                      }}>
                      {pageData.qmi_detail.schedule_button_text}
                    </a>
                    <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.show==='reserve_form'} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            {stageForm ? 
                          <StageQmiReserveForm form_details={this.props.page_data.qmi_detail.tour_details} agent_name={this.props.page_data.qmi_detail.agent_info} division_name={this.props.page_data.qmi_detail.division_name} homesiteNumber={this.props.page_data.qmi_detail.homesite_number}/>
                          :
                          <QmiReserveForm form_details={this.props.page_data.qmi_detail.tour_details} agent_name={this.props.page_data.qmi_detail.agent_info} division_name={this.props.page_data.qmi_detail.division_name} homesiteNumber={this.props.page_data.qmi_detail.homesite_number} />} 
                            </Modal.Body>
                        </Modal>   
                   </>
                  }
                  {isSticky &&
                    <>
                    <a href="javascript:void(0)"
                    class="hero-subnav__button cursor flex full-height hero-subnav__button--text flex-grow flex-shrink text-align--left wrap"
                    // data-fancybox="tour_forms"
                    // data-src="#tour_forms"
                    id="tour_click_btns"
                    onClick={()=>{
                      window.dataLayer.push({'event':'homeReservationFormClick'})
                      this.handleShow('reserve_form')
                    }}
                    style={{
                      '--flex-align-items': 'flex-start',
                      '--flex-direction': 'column',
                      '--flex-justify-content': 'center',
                      'background-color': '#D2ED2B',
                      'border': 'none',
                      'color': 'var(--black)',
                      'flex': 'var(--hero-subnav-button-small-flex, inherit)',
                      'line-height': '1.2',
                      'margin': '0',
                      'min-width': 'var(--hero-subnav-button-small-min-width, max(127px, 38.97435%))',
                      'text-decoration': 'none',
                      'width': 'auto',
                    }}>
                    {pageData.qmi_detail.schedule_button_text}
                  </a>
                  <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.show==='reserve_form'} onHide={this.handleClose}>
                          <Modal.Header closeButton>
                          </Modal.Header>
                          <Modal.Body>
                          {stageForm ? 
                          <StageQmiReserveForm form_details={this.props.page_data.qmi_detail.tour_details} agent_name={this.props.page_data.qmi_detail.agent_info} division_name={this.props.page_data.qmi_detail.division_name} homesiteNumber={this.props.page_data.qmi_detail.homesite_number}/>
                          :
                          <QmiReserveForm form_details={this.props.page_data.qmi_detail.tour_details} agent_name={this.props.page_data.qmi_detail.agent_info} division_name={this.props.page_data.qmi_detail.division_name} homesiteNumber={this.props.page_data.qmi_detail.homesite_number} />} 
                          </Modal.Body>
                      </Modal>   
                    </>
                  }
                  </>
                ) : (
                  posttype === 'mpc' || posttype === 'neighborhood' ?
                      data.community_status !== 'Sold Out' ? 
                        <a href="#interest-form"
                          class="hero-subnav__button cursor flex full-height hero-subnav__button--text flex-grow flex-shrink text-align--left wrap"
                          onClick={e => this.onClickInterestList(e)}
                          style={{
                            '--flex-align-items': 'flex-start',
                            '--flex-direction': 'column',
                            '--flex-justify-content': 'center',
                            'background-color': '#D2ED2B',
                            'border': 'none',
                            'color': 'var(--black)',
                            'flex': 'var(--hero-subnav-button-small-flex, inherit)',
                            'line-height': '1.2',
                            'margin': '0',
                            'min-width': 'var(--hero-subnav-button-small-min-width, max(127px, 38.97435%))',
                            'text-decoration': 'none',
                            'width': 'auto',
                          }}>
                          <div class="hero-subnav__button-title hide-mobile"
                            style={{
                              'font-size': 'var(--hero-subnav-button-title-font-size, 16px)',
                            }}>
                            {data.hero_subnav.join_the_interest_list_cta_headline || 'Join the interest list'}
                          </div>
        
                          <div class="hero-subnav__button-title hide-desktop"
                            style={{
                              'font-size': 'var(--hero-subnav-button-title-font-size, 16px)',
                              'line-height': '0.8',
                            }}>
                            {data.hero_subnav.join_the_interest_list_cta_headline_mobile || data.hero_subnav.join_the_interest_list_cta_headline || 'Join the list'}
                          </div>
        
                          {data.hero_subnav.join_the_interest_list_cta_subtext &&
                            <div class="hero-subnav__button-subtitle hide-mobile font-regular"
                              style={{
                                'color': '#7E8D31',
                                'font-size': '13px',
                              }}>
                              {data.hero_subnav.join_the_interest_list_cta_subtext}
                            </div>
                          }
                        </a> 
                        : ''
                    : 
                    <a href="#interest-form"
                      class="hero-subnav__button cursor flex full-height hero-subnav__button--text flex-grow flex-shrink text-align--left wrap"
                      onClick={e => this.onClickInterestList(e)}
                      style={{
                        '--flex-align-items': 'flex-start',
                        '--flex-direction': 'column',
                        '--flex-justify-content': 'center',
                        'background-color': '#D2ED2B',
                        'border': 'none',
                        'color': 'var(--black)',
                        'flex': 'var(--hero-subnav-button-small-flex, inherit)',
                        'line-height': '1.2',
                        'margin': '0',
                        'min-width': 'var(--hero-subnav-button-small-min-width, max(127px, 38.97435%))',
                        'text-decoration': 'none',
                        'width': 'auto',
                      }}>
                      <div class="hero-subnav__button-title hide-mobile"
                        style={{
                          'font-size': 'var(--hero-subnav-button-title-font-size, 16px)',
                        }}>
                        {data.hero_subnav.join_the_interest_list_cta_headline || 'Join the interest list'}
                      </div>
    
                      <div class="hero-subnav__button-title hide-desktop"
                        style={{
                          'font-size': 'var(--hero-subnav-button-title-font-size, 16px)',
                          'line-height': '0.8',
                        }}>
                        {data.hero_subnav.join_the_interest_list_cta_headline_mobile || data.hero_subnav.join_the_interest_list_cta_headline || 'Join the list'}
                      </div>
    
                      {data.hero_subnav.join_the_interest_list_cta_subtext &&
                        <div class="hero-subnav__button-subtitle hide-mobile font-regular"
                          style={{
                            'color': '#7E8D31',
                            'font-size': '13px',
                          }}>
                          {data.hero_subnav.join_the_interest_list_cta_subtext}
                        </div>
                      }
                    </a>
                )}

              <button class="hero-subnav__button cursor flex full-height hero-subnav__button--image relative flex-grow flex-shrink min-width text-align--left wrap"
                data-chat-modal-open-button
                type="button"
                style={{
                  '--flex-align-items': 'flex-start',
                  '--flex-direction': 'column',
                  '--flex-justify-content': 'center',
                  '--min-width-desktop': !isSticky ? '310px' : '250px',
                  '--min-width-mobile': 'auto',
                  'background-color': '#E7FD5E',
                  'border': 'none',
                  'color': 'var(--black)',
                  'flex': '1 auto 0',
                  'line-height': '1.2',
                  'margin': '0',
                  'width': 'auto',
                }}
                onClick={e => this.openChatModal(e)}>
                <div class="hero-subnav__button-title hide-desktop"
                  style={{
                    'font-size': 'var(--hero-subnav-button-title-font-size, 16px)',
                    'max-width': 'calc(100% - 50px)',
                    'line-height': '0.8',
                    'text-align': 'left',
                  }}>
                  {data.hero_subnav.chat_cta_headline || 'Chat with an expert'}
                </div>

                <div class="hero-subnav__button-title hide-mobile"
                  style={{
                    'font-size': 'var(--hero-subnav-button-title-font-size, 16px)',
                    'text-align': 'left',
                  }}>
                  {data.hero_subnav.chat_cta_headline_mobile || data.hero_subnav.chat_cta_headline || 'Chat with an expert'}
                </div>

                {data.hero_subnav.chat_cta_subtext &&
                  <div class="hero-subnav__button-subtitle hide-mobile font-regular"
                    style={{
                      'color': '#7E8D31',
                      'font-size': '13px',
                    }}>
                    {data.hero_subnav.chat_cta_subtext}
                  </div>
                }

                <img class="hero-subnav__button-image absolute"
                  alt="Chat with an expert"
                  src={agent_info.agent_thumbnail}
                  style={{
                    '--absolute-top': '5px',
                    '--absolute-top-desktop': isSticky ? '5px' : '50%',
                    '--right': 'var(--hero-subnav-button-image-right, 24px)',
                    'border-radius': '50%',
                    'height': 'var(--hero-subnav-button-image-size, 40px)',
                    'transform': 'var(--hero-subnav-button-image-transform, none)',
                    'width': 'var(--hero-subnav-button-image-size, 40px)',
                  }}/>
              </button>
            </div>

            <div class="hero-subnav__links-container flex-shrink flex-grow swiper width wrap swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden"
              data-swiper='{
                "slidesPerView": "auto",
                "spaceBetween": 18,
                "breakpoints": {
                  "1024": {
                    "spaceBetween": 25
                  }
                }
              }'
              style={{
                '--width-desktop': 'calc(100% - 510px)',
                '--width-mobile': '100%',
                'background-color': '#97B700',
                'height': 'var(--hero-subnav-links-height, 50px)',
                'flex-grow': '1',
                'flex-shrink': '0',
                'left': '0',
                'max-width': 'none',
                'position': 'absolute',
              }}>
              <div class="hero-subnav__links flex full-height swiper-wrapper full-height"
                style={{
                  '--flex-align-items': 'center',
                  '--flex-direction': 'row',
                  '--flex-justify-content': 'var(--hero-subnav-links-justify-content, flex-start)',
                  'position': 'absolute',
                  'height': isSticky ? '50px' : '98px',
                  'left': 'var(--wrap-padding-h)',
                  'top': isSticky ? '0' : '5px',
                  'transform': 'translate3d(0px, 0px, 0px)',
                  'transition-duration': '0ms',
                  'width': '100%',
                }}>
                {subnavLinks.map((link, i) =>
                  <a class="hero-subnav__link flex-shrink font--bold no-underline swiper-slide uppercase"
                    href={link.slug === 'community-map' ? 'javascript:void(0)' :`#${link.slug}`}
                    onClick={e => this.smoothScroll(e,link)}
                    data-sticky-subnav-link={link.slug}
                    style={{
                      'color': 'rgb(255, 255, 255)',
                      'font-size': 'var(--hero-subnav-link-font-size, 12px)',
                      'height': 'auto',
                      'letter-spacing': '0.15em',
                      'margin-right': i === subnavLinks.length - 1 ? '0' : '48px',
                      'width': 'auto',
                    }}>
                    {link.title}
                  </a>
                )}
                {data.hasOwnProperty('component') && data.component.filter(x => x.acf_fc_layout === 'inline_component').length > 0 ?
                   data.component.map(x => x.acf_fc_layout === 'inline_component' && x.section_id === 'community-map' ?
                   <Modal className="sitemap_modal" show={this.state.showHide}>
                   <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                   <Modal.Title>  
                    {x.iframe_heading && x.iframe_heading}
                   {x.iframe_subtext && <p tabindex="0">{x.iframe_subtext}</p>}
                   </Modal.Title>
                   </Modal.Header>
                   <Modal.Body><iframe tabindex="0" src={x.cta_link} className="sitemap_iframe" title="sitemap iframe"></iframe></Modal.Body>
                   </Modal> : '')
                : ''
                }
              </div>
            </div>
          </nav>

          {(this.props.hasOwnProperty('hide_details') && !this.props.hide_details) || !this.props.hasOwnProperty('hide_details') &&
            <div class={`hero-subnav__details${isSticky ? ' hero-subnav__details--sticky hide-mobile' : ''} flex relative section-padding wrap`}
              style={{
                '--flex-direction': 'var(--hero-subnav-details-flex-direction, column)',
                '--flex-justify-content': 'var(--hero-subnav-details-justify-content, flex-start)',
                '--section-padding-bottom': '0',
                '--section-padding-top': '28px',
                '--section-padding-bottom-desktop': isSticky ? '10px' : '50px',
                '--section-padding-top-desktop': isSticky ? '14px' : '48px',
                'background-color': '#fafafa',
                'max-width': isSticky ? 'none' : '1920px',
              }}>
              <div class="hero-subnav__details-info flex-grow">
                <strong class="hero-subnav__title title"
                  style={{
                    '--title-font-size': isSticky ? '30px' : 'var(--hero-subnav-title-font-size, 32px)',
                  }}>
                  {data.title}
                </strong>

                <div class="hero-subnav__copy rte"
                  style={{
                    'color': '#848484',
                    'font-size': isSticky ? '13px' : 'var(--hero-subnav-copy-font-size, 14px)',
                    'line-height': 'var(--hero-subnav-copy-line-height, 1.57142)',
                    'margin-top': isSticky ? '4px' : 'var(--hero-subnav-copy-margin-top, 14.8px)',
                  }}>
                  <p>
                    <b class={isSticky ? '' : 'badge'} style={{'--badge-margin': '0 14px 10px 0'}}>{data.community_status}</b> <span class="hero-subnav__copy-text">
                      {posttype === 'qmi' && <>{data.price_value}</>}
                      {posttype !== 'qmi' && data.price_value && (<>New homes in <a href={data.submarket_slug}>{data.city}, {data.state_title}</a>. {data.price_value}</>)}
                      {posttype !== 'qmi' && !data.price_value && (
                          <>New homes coming soon in <a href={data.submarket_slug}>{data.city}, {data.state_title}</a></>
                      )}
                    </span>
                  </p>
                </div>
              </div>

              <div class="hero-subnav__meta-items flex"
                style={{
                  '--display': 'var(--hero-subnav-meta-display, flex)',
                  '--flex-direction': 'var(--hero-subnav-meta-flex-direction, column)',
                  'align-self': isSticky ? 'center' : 'auto',
                  'border-color': '#DEDEDE',
                  'border-style': 'solid',
                  'border-width': 'var(--hero-subnav-meta-border-width, 1px 0 0)',
                  'margin-top': 'var(--hero-subnav-meta-margin-top, 28px)',
                  'order': 'var(--hero-subnav-meta-order, inherit)',
                  'padding': 'var(--hero-subnav-meta-padding, 0)',
                  'width': 'var(--hero-subnav-meta-width, 100%)',
                }}>
                {data.square_feet && (
                    <div class="hero-subnav__meta-item flex font--medium"
                    style={{
                        '--flex-align-items': 'center',
                        '--flex-display': 'var(--hero-subnav-meta-item-display, flex)',
                        '--flex-direction': 'row-reverse',
                        '--flex-justify-content': 'space-between',
                        'border-bottom': 'var(--hero-subnav-meta-item-border-bottom, 1px solid #DEDEDE)',
                        'border-right': 'var(--hero-subnav-meta-item-border-right, none)',
                        'flex': 'var(--hero-subnav-meta-item-flex, auto)',
                        'flex-shrink': '0',
                        'font-size': 'var(--hero-subnav-meta-item-font-size, 18px)',
                        'padding': 'var(--hero-subnav-meta-item-padding, 18px 0)',
                        'text-align': 'var(--hero-subnav-meta-item-text-align, left)',
                        'width': 'var(--hero-subnav-meta-item-width, 100%)',
                    }}>
                        {data.square_feet}
                        <div class="hero-subnav__meta-eyebrow eyebrow"
                            style={{
                            '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                            'margin-top': isSticky ? 'var(--hero-subnav-meta-item-eyebrow-margin-top, 2px)' : 'var(--hero-subnav-meta-item-eyebrow-margin-top, 12px)',
                            }}>
                            Sq. Footage
                        </div>
                    </div>
                )}

                {data.bed && (
                    <div class="hero-subnav__meta-item flex font--medium"
                    style={{
                        '--flex-align-items': 'center',
                        '--flex-display': 'var(--hero-subnav-meta-item-display, flex)',
                        '--flex-direction': 'row-reverse',
                        '--flex-justify-content': 'space-between',
                        'border-bottom': 'var(--hero-subnav-meta-item-border-bottom, 1px solid #DEDEDE)',
                        'border-right': 'var(--hero-subnav-meta-item-border-right, none)',
                        'flex': 'var(--hero-subnav-meta-item-flex, auto)',
                        'flex-shrink': '0',
                        'font-size': 'var(--hero-subnav-meta-item-font-size, 18px)',
                        'margin-left': 'var(--hero-subnav-meta-item-margin-left-off, 0)',
                        'padding': 'var(--hero-subnav-meta-item-padding, 18px 0)',
                        'text-align': 'var(--hero-subnav-meta-item-text-align, left)',
                        'width': 'var(--hero-subnav-meta-item-width, 100%)',
                    }}>
                        {data.bed}
                        <div class="hero-subnav__meta-eyebrow eyebrow"
                            style={{
                            '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                            'margin-top': isSticky ? 'var(--hero-subnav-meta-item-eyebrow-margin-top, 2px)' : 'var(--hero-subnav-meta-item-eyebrow-margin-top, 12px)',
                            }}>
                            Bedrooms
                        </div>
                    </div>                    
                )}
                
                {data.bath && (
                    <div class="hero-subnav__meta-item flex font--medium"
                    style={{
                        '--flex-align-items': 'center',
                        '--flex-display': 'var(--hero-subnav-meta-item-display, flex)',
                        '--flex-direction': 'row-reverse',
                        '--flex-justify-content': 'space-between',
                        'border-bottom': 'var(--hero-subnav-meta-item-border-bottom, 1px solid #DEDEDE)',
                        'border-right': 'var(--hero-subnav-meta-item-border-right, none)',
                        'flex': 'var(--hero-subnav-meta-item-flex, auto)',
                        'flex-shrink': '0',
                        'font-size': 'var(--hero-subnav-meta-item-font-size, 18px)',
                        'margin-left': 'var(--hero-subnav-meta-item-margin-left-off, 0)',
                        'padding': 'var(--hero-subnav-meta-item-padding, 18px 0)',
                        'text-align': 'var(--hero-subnav-meta-item-text-align, left)',
                        'width': 'var(--hero-subnav-meta-item-width, 100%)',
                    }}>
                        {data.bath}
                        <div class="hero-subnav__meta-eyebrow eyebrow"
                            style={{
                            '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                            'margin-top': isSticky ? 'var(--hero-subnav-meta-item-eyebrow-margin-top, 2px)' : 'var(--hero-subnav-meta-item-eyebrow-margin-top, 12px)',
                            }}>
                            Bathrooms
                        </div>
                    </div>
                )}

                {data.garage && (
                    <div class="hero-subnav__meta-item flex font--medium"
                    style={{
                        '--flex-align-items': 'center',
                        '--flex-display': 'var(--hero-subnav-meta-item-display, flex)',
                        '--flex-direction': 'row-reverse',
                        '--flex-justify-content': 'space-between',
                        'border-bottom': 'var(--hero-subnav-meta-item-border-bottom, 1px solid #DEDEDE)',
                        'flex': 'var(--hero-subnav-meta-item-flex, auto)',
                        'flex-shrink': '0',
                        'font-size': 'var(--hero-subnav-meta-item-font-size, 18px)',
                        'margin-left': 'var(--hero-subnav-meta-item-margin-left-off, 0)',
                        'padding': 'var(--hero-subnav-meta-item-last-padding, 18px 0)',
                        'text-align': 'var(--hero-subnav-meta-item-text-align, left)',
                        'width': 'var(--hero-subnav-meta-item-width, 100%)',
                    }}>
                        {data.garage}
                        <div class="hero-subnav__meta-eyebrow eyebrow"
                            style={{
                            '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                            'margin-top': isSticky ? 'var(--hero-subnav-meta-item-eyebrow-margin-top, 2px)' : 'var(--hero-subnav-meta-item-eyebrow-margin-top, 12px)',
                            }}>
                            Bay Garage
                        </div>
                    </div>
                )}
              </div>
            </div>
          }
        </div>
        {!isSticky &&
          <div class="details__modal max-height"
            style={{
              '--max-height-desktop': 'calc(100vh - var(--details-modal-bottom))',
              '--max-height-mobile': '100vh',
              'background-color': '#fff',
              'bottom': 'var(--details-modal-bottom, 0)',
              'box-shadow': 'var(--details-modal-box-shadow, 4px 8px 12px rgb(0 0 0 / 10%))',
              'box-shadow': 'var(--details-modal-box-shadow, 0 -10px 12px rgb(0 0 0 / 10%))',
              'opacity': 'var(--details-modal-opacity, 0)',
              'overflow': 'auto',
              'overscroll-behavior': 'contain',
              'pointer-events': 'var(--details-modal-pointer-events, none)',
              'position': 'var(--details-modal-position, fixed)',
              'right': 'var(--details-modal-right, 0)',
              'transform': 'var(--details-modal-transform, translateY(10px))',
              'transition': 'var(--details-modal-transition, 450ms ease-in-out)',
              'width': 'var(--details-modal-width, 100%)',
              'z-index': '1001',
            }}>
            <figure class="details__modal-media aspect-ratio"
              data-youtube-embed-container={video !== null && videoType === 'youtube' ? 'true' : 'false'}
              style={{
                '--aspect-ratio-height': '9',
                '--aspect-ratio-width': '16',
                'background-color': 'var(--black)',
                'margin-bottom': '0',
              }}>
              {/* {agent_info.video && <video class="details__modal-video lazy object-fit" data-details-modal-video data-src={agent_info.video}></video>} */}
              {video !== null ? video : ''}

              {video !== null && (videoType !== 'youtube' && videoType !== 'vidyard') &&
                <>
                  <span class="details__modal-video-title eyebrow pointer-events-none"
                    style={{
                      'bottom': 'var(--details-modal-padding-h, 24px)',
                      'color': '#fff',
                      'font-size': '10px',
                      'left': 'var(--details-modal-padding-h, 24px)',
                      'position': 'absolute',
                    }}>
                    {agent_info.agent_nhs_heading ? agent_info.agent_nhs_heading : 'Your New Home Specialist'}
                  </span>

                  {closeButton}
                </>
              }
              {!video && agent_info.agent_thumbnail &&
                <>
                  <img class="lazy object-fit" data-src={agent_info.agent_thumbnail} alt="" role="presentation" aria-hidden="true"></img>

                  <span class="details__modal-video-title eyebrow pointer-events-none"
                    style={{
                      'bottom': 'var(--details-modal-padding-h, 24px)',
                      'color': '#fff',
                      'font-size': '10px',
                      'left': 'var(--details-modal-padding-h, 24px)',
                      'position': 'absolute',
                    }}>
                    {agent_info.agent_nhs_heading ? agent_info.agent_nhs_heading : 'Your New Home Specialist'}
                  </span>

                  {closeButton}
                </>
              }
            </figure>

            <div class="details__modal-info"
              style={{
                'padding': '20px var(--details-modal-padding-h, 24px)',
                'position': 'relative',
              }}>
              <div class="details__modal-info-copy flex"
                style={{
                  '--flex-align-items': 'flex-start',
                  '--flex-direction': 'row',
                  '--flex-justify-content': 'space-between',
                }}>
                <div class="details__modal-info-copy-wrap">
                  {video !== null && (videoType === 'youtube' || videoType === 'vidyard') &&
                    <>
                      <div class="details__modal-video-title eyebrow pointer-events-none"
                        style={{
                          'color': '#242424',
                          'font-size': '10px',
                          'margin-bottom': '10px',
                        }}>
                        {agent_info.agent_nhs_heading ? agent_info.agent_nhs_heading : 'Your New Home Specialist'}
                      </div>
                    </>
                  }

                  <div class="details__contacts details__contacts--modal"
                    style={{
                      'color': '#242424',
                      'font-size': '18px',
                    }}>
                    {agent_info.agents_contact_name ? agent_info.agents_contact_name : ''}
                  </div>

                  <a class="details__number details__number--modal"
                    href={`tel:${agent_info.agents_contact_phone}`}
                    style={{
                      'display': 'inline-block',
                      'font-weight': 'bold',
                      'font-size': '27px',
                      'margin-top': 'var(--details-number-margin-top, 7px)',
                      'text-decoration': 'none',
                    }} onClick={() => this.calltoaction_data_layer_push('clickToCall')}>
                    {agent_info.agents_contact_phone}
                  </a>
                </div>

                <div class="details__modal-info-buttons flex"
                  style={{
                    '--flex-align-items': 'flex-end',
                    '--flex-direction': 'column',
                    '--flex-display': 'inline-flex',
                    'margin-left': '5px',
                  }}>
                  <a class="button button--small details__modal-info-btn survey-chat-btn"
                    href={`tel:${agent_info.agents_contact_phone}`}
                    onClick={() => this.calltoaction_data_layer_push('clickToCall')}
                    style={{
                      '--btn-color': '#555',
                      '--btn-font-size': '14px',
                      '--btn-min-height': '50px',
                      '--btn-min-width': '168px',
                      'padding': '15px 15px 12px',
                      'width': '100%',
                    }}>
                    <svg class="details__modal-info-btn-icon details__modal-info-btn-icon--phone"
                      style={{
                        'enable-background': 'new 0 0 122.88 122.27',
                        'height': 'auto',
                        'margin-right': '14px',
                        'position': 'relative',
                        'flex-shrink': '0',
                        'width': '20px',
                        'z-index': '1',
                      }}
                      x="0px" y="0px" viewBox="0 0 122.88 122.27" xmlSpace="preserve"><g><path fill="#555" d="M33.84,50.25c4.13,7.45,8.89,14.6,15.07,21.12c6.2,6.56,13.91,12.53,23.89,17.63c0.74,0.36,1.44,0.36,2.07,0.11 c0.95-0.36,1.92-1.15,2.87-2.1c0.74-0.74,1.66-1.92,2.62-3.21c3.84-5.05,8.59-11.32,15.3-8.18c0.15,0.07,0.26,0.15,0.41,0.21 l22.38,12.87c0.07,0.04,0.15,0.11,0.21,0.15c2.95,2.03,4.17,5.16,4.2,8.71c0,3.61-1.33,7.67-3.28,11.1 c-2.58,4.53-6.38,7.53-10.76,9.51c-4.17,1.92-8.81,2.95-13.27,3.61c-7,1.03-13.56,0.37-20.27-1.69 c-6.56-2.03-13.17-5.38-20.39-9.84l-0.53-0.34c-3.31-2.07-6.89-4.28-10.4-6.89C31.12,93.32,18.03,79.31,9.5,63.89 C2.35,50.95-1.55,36.98,0.58,23.67c1.18-7.3,4.31-13.94,9.77-18.32c4.76-3.84,11.17-5.94,19.47-5.2c0.95,0.07,1.8,0.62,2.25,1.44 l14.35,24.26c2.1,2.72,2.36,5.42,1.21,8.12c-0.95,2.21-2.87,4.25-5.49,6.15c-0.77,0.66-1.69,1.33-2.66,2.03 c-3.21,2.33-6.86,5.02-5.61,8.18L33.84,50.25L33.84,50.25L33.84,50.25z"/></g></svg>
                    <span class="details__modal-info-btn-text">Call us</span>
                  </a>

                  {/* <!-- Offline --> */}
                  {
                  posttype === 'mpc' || posttype === 'neighborhood' ?
                  data.community_status !== 'Sold Out' ?
                    <a class="button button--small details__modal-info-btn survey-chat-btn survey-chat-btn--offline"
                    href="#interest-form"
                    onClick={
                    e => {
                        this.onClickInterestList(e)

                        setTimeout(() => {
                          var $askQLabel = $('label[id*="LblopenQuestion"]:visible')[0]

                          if ($askQLabel) {
                            $askQLabel.click()
                          } else {
                            setTimeout(() => {
                              var $askQLabel = $('label[id*="LblopenQuestion"]:visible')[0]
                              if ($askQLabel) $askQLabel.click()
                            }, 500)
                          }
                        }, 1000)
                      }
                    }
                    style={{
                      '--btn-color': '#555',
                      '--btn-font-size': '14px',
                      '--btn-min-height': '50px',
                      '--btn-min-width': '168px',
                      'margin-top': '6px',
                      'padding': '15px 15px 12px',
                      'width': '100%',
                    }}>
                    <svg class="details__modal-info-btn-icon details__modal-info-btn-icon--comment"
                      style={{
                        'height': 'auto',
                        'margin-right': '14px',
                        'position': 'relative',
                        'flex-shrink': '0',
                        'width': '20px',
                        'z-index': '1',
                      }}
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><g transform="translate(-393 -332)"><rect fill="none" width="21" height="21" transform="translate(393 332)"/><path fill="#555" d="M17.5,1H3.5A3.511,3.511,0,0,0,0,4.5v8.75a3.511,3.511,0,0,0,3.5,3.5v2.626a.875.875,0,0,0,1.488.613L8.227,16.75H17.5a3.511,3.511,0,0,0,3.5-3.5V4.5A3.511,3.511,0,0,0,17.5,1Z" transform="translate(393 332)"/></g></svg>
                    <span class="details__modal-info-btn-text">Ask a question</span>
                    </a>
                  : ''
                  : 
                  <a class="button button--small details__modal-info-btn survey-chat-btn survey-chat-btn--offline"
                    href="#interest-form"
                    onClick={
                      e => {
                        this.onClickInterestList(e)

                        setTimeout(() => {
                          var $askQLabel = $('label[id*="LblopenQuestion"]:visible')[0]

                          if ($askQLabel) {
                            $askQLabel.click()
                          } else {
                            setTimeout(() => {
                              var $askQLabel = $('label[id*="LblopenQuestion"]:visible')[0]
                              if ($askQLabel) $askQLabel.click()
                            }, 500)
                          }
                        }, 1000)
                      }
                    }
                    style={{
                      '--btn-color': '#555',
                      '--btn-font-size': '14px',
                      '--btn-min-height': '50px',
                      '--btn-min-width': '168px',
                      'margin-top': '6px',
                      'padding': '15px 15px 12px',
                      'width': '100%',
                    }}>
                    <svg class="details__modal-info-btn-icon details__modal-info-btn-icon--comment"
                      style={{
                        'height': 'auto',
                        'margin-right': '14px',
                        'position': 'relative',
                        'flex-shrink': '0',
                        'width': '20px',
                        'z-index': '1',
                      }}
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><g transform="translate(-393 -332)"><rect fill="none" width="21" height="21" transform="translate(393 332)"/><path fill="#555" d="M17.5,1H3.5A3.511,3.511,0,0,0,0,4.5v8.75a3.511,3.511,0,0,0,3.5,3.5v2.626a.875.875,0,0,0,1.488.613L8.227,16.75H17.5a3.511,3.511,0,0,0,3.5-3.5V4.5A3.511,3.511,0,0,0,17.5,1Z" transform="translate(393 332)"/></g></svg>
                    <span class="details__modal-info-btn-text">Ask a question</span>
                  </a>
                  }

                  {/* <!-- Online --> */}
                  <a class="button button--small details__modal-info-btn survey-chat-btn survey-chat-btn--online"
                    href="javascript:void(0)"
                    style={{
                      '--btn-color': '#555',
                      '--btn-font-size': '14px',
                      '--btn-display': 'none',
                      '--btn-min-height': '50px',
                      '--btn-min-width': '168px',
                      'margin-top': '6px',
                      'padding': '15px 15px 12px',
                      'width': '100%',
                    }}>
                    <svg class="details__modal-info-btn-icon details__modal-info-btn-icon--comment"
                      style={{
                        'height': 'auto',
                        'margin-right': '14px',
                        'position': 'relative',
                        'flex-shrink': '0',
                        'width': '20px',
                        'z-index': '1',
                      }}
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><g transform="translate(-393 -332)"><rect fill="none" width="21" height="21" transform="translate(393 332)"/><path fill="#555" d="M17.5,1H3.5A3.511,3.511,0,0,0,0,4.5v8.75a3.511,3.511,0,0,0,3.5,3.5v2.626a.875.875,0,0,0,1.488.613L8.227,16.75H17.5a3.511,3.511,0,0,0,3.5-3.5V4.5A3.511,3.511,0,0,0,17.5,1Z" transform="translate(393 332)"/></g></svg>
                    <span class="details__modal-info-btn-text">Live Chat</span>
                  </a>
                </div>
              </div>

              <small class="details__modal-meta"
                style={{
                  'border-top': '1px solid #DEDEDE',
                  'color': '#B0B0B0',
                  'display': 'block',
                  'font-size': '10px',
                  'margin-top': '32px',
                  'padding-top': '12px',
                }}>
                {agent_info.dre_license}
              </small>
              {video !== null && videoType === 'youtube' &&
                <>
                  {closeButton}
                </>
              }
            </div>
          </div>
        }
      </>
    )
  }
}

export default HeroSubNav
